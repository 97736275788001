
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NewHero from '../components/NewHero';
import Card from '../components/Card';
import Counter from '../components/Counter';
import FAQAccordion from '../components/Accordion';
import SponsorsSection from '../components/SponsorSection';

const Snowflake: React.FC<{ delay: number }> = ({ delay }) => {
  const size = Math.random() * 4 + 2;
  const startPosition = `${Math.random() * 100}%`;
  return (
    <div
      className="absolute animate-fall"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        left: startPosition,
        top: '-20px',
        backgroundColor: '#AAAAAA',
        opacity: 0.6,
        borderRadius: '50%',
        filter: 'blur(1px)',
        animation: `fall ${Math.random() * 10 + 10}s linear infinite ${delay}s`
      }}
    />
  );
};

const SnowflakeBackground: React.FC = () => {
  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {[...Array(50)].map((_, i) => (
        <Snowflake key={i} delay={Math.random() * 10} />
      ))}
    </div>
  );
};

const Home: React.FC = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;

      requestAnimationFrame(() => {
        [...Array(20)].forEach((_, i) => {
          const speed = 0.1 + (i % 3) * 0.1;
          document.documentElement.style.setProperty(
            `--scroll-offset-${i}`,
            `${scrolled * speed}px`
          );
        });
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const faqItems = [
    {
      title: "Who can participate in M(IT)^2?",
      content: "Everybody is eligible to compete in <strong class='text-mitit-red'>M(IT)^2</strong>!<br><br>We will divide contestants into different age groups, such as the pre-college division, college division, and post-college division, and give out the prizes accordingly. Regardless of your division, you will have the opportunity to try out our problems live during the contest window."
    },
    {
      title: "What does the tournament look like?",
      content: "Contestants will compete in teams of <b>at most three</b>, working to solve the problems together.<br><br>There are two main rounds, each 2.5 hours with live scoreboard. Beside the main rounds, we will also host various <b>mini-activities and workshops during Activities Night on the day before!</b> For more information about the format and schedule, please check out our <strong class='text-mitit-red'>Logistics Page</strong>"
    },
    {
      title: "Where will the tournament be held?",
      content: "We are an in-person tournament! So the tournament will be hosted live on-site. More specifically, we will host the majority of our event at the <b>Huntington Hall (10-250)</b>.<br><br>That being said, we are planning to host an online mirror of the contest, so that participants who unfortunately cannot come to MIT in-person can still compete and win certain prizes!"
    },
    {
      title: "I still have more questions!",
      content: "If you have any more questions about the tournament or anything related to M(IT)^2, please feel free to shoot us an email at <strong class='text-mitit-red'><a href=\"mailto:mitit@mit.edu\">mitit@mit.edu!</a></strong>"
    }
  ];

  return (
    <div className="relative min-h-screen bg-blueberry-milk">
      <SnowflakeBackground />
      <div className="relative z-20">
        <NewHero />

        <section className="container mx-auto px-4 py-12 md:py-20" id="who-are-we">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <Card
              frontImage="/landing_page/assets/images/contest.jpg"
              backImage="/landing_page/assets/cards/card-back.png"
              isHorizontal={true}
              altText="MIT Informatics Tournament Team"
              className="max-w-2xl"
            />
            <div>
              <div className="inline-block mb-6 px-6 py-2 rounded-full border-2 border-mitit-red">
                <h2 className="font-departure-mono text-mitit-red">Who Are We?</h2>
              </div>
              <h3 className="text-3xl md:text-4xl font-outfit text-winter-night mb-6 text-bold">
                The MIT Informatics Tournament, <span className="font-departure-mono">M(IT)^2</span>
              </h3>
              <p className="text-lg text-winter-night mb-8">
                Through our tournament, we strive to promote a hub of passionate competitive programmers
                anchored at MIT.
              </p>
              <div className="flex flex-wrap gap-4">
                <a href="/Registration" className="primary-button">
                  REGISTER FOR OUR CONTEST
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Statistics Section */}
        <section className="container mx-auto px-4 py-12">
          <div className="grid md:grid-cols-3 gap-8">
            <Counter
              end={20}
              description="high quality problems curated by our brilliant Problems team"
            />
            <Counter
              end={1900}
              description="contestants who have competed in our two international contests"
            />
            <Counter
              end={70}
              description="countries and territories represented by our contestants"
            />
          </div>
        </section>

        {/* FAQ Section */}
        <section className="container mx-auto px-4 py-12 md:py-20">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block mb-6 px-6 py-2 rounded-full border-2 border-mitit-red">
                <h2 className="font-departure-mono text-mitit-red">What's It Like?</h2>
              </div>
              <FAQAccordion items={faqItems} />
            </div>
            <Card
              frontImage="/landing_page/assets/cards/mug.png"
              backImage="/landing_page/assets/cards/card-back.png"
              isHorizontal={false}
              altText="MITIT Mascot"
              className="max-w-md mx-auto"
            />
          </div>
        </section>

        {/* Sponsors Section */}
        <SponsorsSection />
      </div>
    </div>
  );
};

export default Home;