import React, { useState } from 'react';
import DOMPurify from 'dompurify';

interface AccordionItemProps {
  title: string;
  content: string;
  isOpen: boolean;
  onClick: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ 
  title, 
  content, 
  isOpen, 
  onClick 
}) => {
  // Safely render HTML content
  const createMarkup = (htmlContent: string) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <div className="border-b border-winter-night/10">
      <button
        className={`
          w-full text-left py-4 px-6 cursor-pointer transition-colors duration-200 
          hover:bg-winter-night/5 ${isOpen ? 'bg-winter-night/5' : ''}
        `}
        onClick={onClick}
      >
        <div className="flex justify-between items-center">
          <span className="font-departure-mono text-lg text-winter-night">{title}</span>
          <span className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
            ▼
          </span>
        </div>
      </button>
      <div
        className={`
          overflow-hidden transition-all duration-300 ease-in-out
          ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}
        `}
      >
        <div 
          className="p-6 font-outfit text-winter-night"
          dangerouslySetInnerHTML={createMarkup(content)}
        />
      </div>
    </div>
  );
};

interface FAQAccordionProps {
  items: Array<{
    title: string;
    content: string;
  }>;
}

const FAQAccordion: React.FC<FAQAccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div className="w-full rounded-lg overflow-hidden">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={() => setOpenIndex(openIndex === index ? null : index)}
        />
      ))}
    </div>
  );
};

export default FAQAccordion;