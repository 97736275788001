import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './store';
import AppRoutes from './routes';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import './styles/globals.css';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <AppRoutes />
      </Router>
    </Provider>
  );
}

export default App;