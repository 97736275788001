import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Registration from './pages/Registration';
import Profile from './pages/Profile';
import Hub from './pages/Hub';
import About from './pages/About';
import Logistics from './pages/Logistics';
import RegistrationSuccess from './pages/RegistrationSuccess';
import VerifyEmail from './pages/VerifyEmail';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Auth routes without layout */}
      {/* <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Registration />} /> */}

      {/* Routes with layout */}
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route path="/hub" element={<Hub />} />
        <Route path="/about" element={<About />} />
        <Route path="/logistics" element={<Logistics />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} /> */}
        {/* <Route path="/verify-email/:token" element={<VerifyEmail />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;