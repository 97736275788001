import React, { useEffect, useState } from 'react';

const ScrambleText: React.FC<{ text: string }> = ({ text }) => {
  const [displayText, setDisplayText] = useState(text.replace(/./g, ' '));
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  
  useEffect(() => {
    let frame = 0;
    let iterator = 0;
    const frameRate = 20;
    const duration = 150; // Match fade-in duration (0.1s)
    const frames = Math.floor(duration / (1000 / frameRate));
    
    const update = () => {
      let output = '';
      let complete = 0;
      
      for (let i = 0; i < text.length; i++) {
        if (i < iterator) {
          output += text[i];
          complete++;
        } else if (text[i] === ' ' || text[i] === '^' || text[i] === '(' || text[i] === ')') {
          output += text[i];
        } else {
          output += chars[Math.floor(Math.random() * chars.length)];
        }
      }
      
      setDisplayText(output);
      
      if (frame < frames) {
        frame++;
      } else {
        frame = 0;
        iterator++;
      }
      
      if (complete < text.length) {
        requestAnimationFrame(update);
      }
    };
    
    update();
  }, [text]);

  return <span>{displayText}</span>;
};

export default ScrambleText;