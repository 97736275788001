import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface CardProps {
  frontImage: string;
  backImage: string;
  isHorizontal?: boolean;
  altText: string;
  children?: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ 
  frontImage, 
  backImage, 
  isHorizontal = false, 
  altText, 
  children,
  className = ''
}) => {
  const [isFlipped, setIsFlipped] = useState(true);
  const [hasAnimated, setHasAnimated] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && !hasAnimated) {
      setTimeout(() => {
        setIsFlipped(false);
        setHasAnimated(true);
      }, 300);
    }
  }, [inView, hasAnimated]);

  const handleClick = () => {
    if (hasAnimated) {
      setIsFlipped(!isFlipped);
    }
  };

  // Determine which back image to use based on orientation
  const backImageSrc = isHorizontal 
    ? backImage.replace('.png', '-horizontal.png')
    : backImage;

  return (
    <div
      ref={ref}
      className={`perspective-1000 cursor-pointer w-full ${
        isHorizontal ? 'max-w-2xl' : 'max-w-xs'  // Reduced from max-w-md
      } ${className}`}
      onClick={handleClick}
      style={{
        aspectRatio: isHorizontal ? '7/5' : '5/7',
      }}
    >
      <div
        className={`
          relative w-full h-full transition-transform duration-200 transform-style-3d
          ${isFlipped ? 'rotate-y-180' : ''}
        `}
      >
        {/* Front of card */}
        <div
          className="absolute w-full h-full backface-hidden rounded-2xl shadow-lg 
                     hover:shadow-xl transition-shadow duration-300"
        >
          <img
            src={frontImage}
            alt={altText}
            className="w-full h-full object-cover rounded-2xl"
          />
          {children && (
            <div className="absolute inset-0 rounded-2xl">
              {children}
            </div>
          )}
        </div>

        {/* Back of card */}
        <div
          className="absolute w-full h-full backface-hidden rounded-2xl shadow-lg 
                     hover:shadow-xl transition-shadow duration-300 rotate-y-180"
        >
          <img
            src={backImageSrc}
            alt="Card Back"
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default Card;