import React from 'react';
import { Link } from 'react-router-dom';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  isLoggedIn: boolean;
  onLogout: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose, isLoggedIn }) => {
  return (
    <div 
      className={`fixed inset-0 z-50 transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="absolute right-0 h-full w-64 bg-january-snow shadow-lg">
        <div className="flex flex-col p-4">
          <button 
            onClick={onClose}
            className="self-end text-winter-night mb-8"
          >
            ✕
          </button>
          <nav className="flex flex-col space-y-4">
            <Link 
              to="/logistics" 
              className="nav-link"
              onClick={onClose}
            >
              Logistics
            </Link>
            <Link 
              to="/about" 
              className="nav-link"
              onClick={onClose}
            >
              About
            </Link>
            <Link 
              to="/blog" 
              className="nav-link"
              onClick={onClose}
            >
              Blog
            </Link>
            {!isLoggedIn ? (
              <>
                <Link 
                  to="/register" 
                  className="primary-button text-center"
                  onClick={onClose}
                >
                  Register Here
                </Link>
                <Link 
                  to="/login" 
                  className="secondary-button text-center"
                  onClick={onClose}
                >
                  Log In
                </Link>
              </>
            ) : (
              <>
                <Link 
                  to="/hub" 
                  className="primary-button text-center"
                  onClick={onClose}
                >
                  M(IT)^2 Hub
                </Link>
                <Link 
                  to="/profile" 
                  className="secondary-button text-center"
                  onClick={onClose}
                >
                  Profile
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;