import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

interface CounterProps {
  end: number;
  suffix?: string;
  description: string;
}

const Counter: React.FC<CounterProps> = ({ end, suffix = '', description }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  });
  const hasStarted = useRef(false);

  useEffect(() => {
    if (inView && !hasStarted.current) {
      hasStarted.current = true;
      const duration = 2000;
      const frames = 60;
      const increment = end / frames;
      let currentCount = 0;
      
      const interval = setInterval(() => {
        currentCount += increment;
        if (currentCount >= end) {
          setCount(end);
          clearInterval(interval);
        } else {
          setCount(Math.floor(currentCount));
        }
      }, duration / frames);

      return () => clearInterval(interval);
    }
  }, [inView, end]);

  return (
    <div ref={ref} className="text-center">
      <div className="text-5xl md:text-6xl font-departure-mono text-mitit-red mb-4">
        {count}{suffix}+
      </div>
      <p className="text-lg text-winter-night font-outfit">
        {description}
      </p>
    </div>
  );
};

export default Counter;