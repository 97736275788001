import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../store/slices/authSlice';
import MobileMenu from './MobileMenu';



const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const { isAuthenticated } = useSelector((state: any) => state.auth);
  const isAuthenticated = true;
  const user = { username: 'mcneilly' };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const header = document.querySelector('header');
    if (header) {
      header.classList.add('opacity-0');
      requestAnimationFrame(() => {
        header.classList.add('transition-opacity', 'duration-500');
        header.classList.remove('opacity-0');
      });
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(clearUser());
    navigate('/');
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 bg-blueberry-milk
        ${isScrolled ? 'shadow-md' : ''}`}
    >
      <div className="container mx-auto px-4 py-3">
        <nav className="flex items-center justify-between">
          <Link to="/" className="flex-shrink-0">
            <img
              src="/landing_page/assets/logos/mitit-logo.svg"
              alt="MITIT Logo"
              className="h-8 w-auto"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8 font-outfit">
            <a
              href="/Logistics"
              className={`nav-link hover:text-mitit-red`}
            >
              Logistics
            </a>
            <a
              href="/About"
              className={`nav-link hover:text-mitit-red`}
            >
              About
            </a>
            <a
              href="/Blogs"
              className={`nav-link hover:text-mitit-red`}
            >
              Blog
            </a>
          </div>

          {/* Desktop Buttons */}
          <div className="hidden md:flex items-center space-x-4">
            {isAuthenticated ? (
              <>
                <a
                  href="/Registration"
                  className="primary-button"
                >
                  Register Here
                </a>
                <a
                  href="/Contest/Login"
                  className="secondary-button"
                >
                  Log In
                </a>
              </>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/hub"
                  className="primary-button"
                >
                  M(IT)^2 Hub
                </Link>
                <div className="relative">
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="secondary-button flex items-center gap-2"
                  >
                    {user?.username}
                    <span className={`transition-transform duration-200 ${dropdownOpen ? 'rotate-180' : ''}`}>▼</span>
                  </button>

                  {dropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg bg-white border border-winter-night/10 py-1">
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-winter-night hover:bg-winter-night/5"
                      >
                        My Profile
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-winter-night hover:bg-winter-night/5"
                      >
                        Logout →
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            className={`md:hidden ${isAuthenticated ? 'text-january-snow' : 'text-winter-night'}`}
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </nav>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        isLoggedIn={isAuthenticated}
        onLogout={handleLogout}
      />
    </header>
  );
};

export default Header;