import React from 'react';
import Card from './Card';
import ScrambleText from './ScrambleText';

interface SponsorLogoProps {
  src: string;
  alt: string;
  size?: 'large' | 'small';
  href?: string;
}

const SponsorLogo: React.FC<SponsorLogoProps> = ({ src, alt, size = 'small', href }) => {
  const Logo = (
    <img
      src={src}
      alt={alt}
      className={`
        ${size === 'large' ? 'max-h-24' : 'max-h-16'}
        w-auto object-contain filter hover:brightness-110 transition-all duration-300
      `}
    />
  );

  return (
    <div className={`
      ${size === 'large' ? 'col-span-1 md:col-span-2' : 'col-span-1'}
      flex items-center justify-center p-4
    `}>
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {Logo}
        </a>
      ) : Logo}
    </div>
  );
};

const SponsorsSection: React.FC = () => {
  return (
    <section className="py-16 md:py-24">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <Card
            frontImage="/landing_page/assets/cards/beaver.jpg"
            backImage="/landing_page/assets/cards/card-back.png"
            isHorizontal={false}
            altText="MITIT Mascot"
            className="max-w-md mx-auto"
          />

          <div>
            <div className="inline-block mb-6 px-6 py-2 rounded-full border-2 border-mitit-red">
              <h2 className="font-departure-mono text-mitit-red">Sponsor Us</h2>
            </div>
            <p className="text-lg text-winter-night mb-6">
              Thank you to these organizations for making our contest possible!
              We would not be able to make such high quality international contests
              without their support.
            </p>
            <p className="text-lg text-winter-night mb-8">
              If you are interested in supporting M(IT)^2, check out our prospectus
              or reach out to us!
            </p>
            <a
              href="mailto:mitit@mit.edu"
              className="primary-button inline-block"
            >
              GET OUR PROSPECTUS
            </a>
          </div>
        </div>

        <div className="text-center mb-12">
          <h2 className="text-3xl font-departure-mono text-mitit-red">
            <ScrambleText text="THANK YOU TO OUR AMAZING SPONSORS!" />
          </h2>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-6 gap-4 md:gap-8 items-center">
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/mit.png"
            alt="MIT"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/jump-trading.png"
            alt="Jump Trading"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/citadel.png"
            alt="Citadel"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/jane-street.png"
            alt="Jane Street"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/hrt.png"
            alt="HRT"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/tower-research.png"
            alt="Tower Research"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/alphastar-academy.png"
            alt="Alphastar Academy"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/non-trivial.png"
            alt="Non-Trivial"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/sam-solutions.png"
            alt="Sam Solutions"
            size="large"
            href="https://www.mit.edu"
          />
          <SponsorLogo
            src="/landing_page/assets/logos/sponsor-logos/mit-eecs.png"
            alt="MIT EECS"
            size="large"
            href="https://www.mit.edu"
          />
        </div>
      </div>
    </section>
  );
};

export default SponsorsSection;