import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from './Card';
import ScrambleText from './ScrambleText';

const NewHero: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="relative h-screen overflow-hidden bg-blueberry-milk">
      {/* Background Image Container */}
      <div
        className={`absolute top-24 left-1 w-1/2 h-1/2 z-0 transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
          }`}
      >
        <img
          src="/landing_page/assets/images/hero-beaver.png"
          alt=""
          className="w-full h-full object-contain opacity-100"
          style={{
            transform: 'scale(1.22)'
          }}
        />
      </div>

      {/* Main Content Container */}
      <div className="relative z-10 h-full max-w-7xl mx-auto px-4 md:px-6">
        {/* Left Card Section */}
        <div className="absolute left-16 bottom-12 transform -rotate-12 w-16 md:w-20 lg:w-24 hidden lg:block">
          <div
            className={`transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
              }`}
          >
            <Card
              frontImage="/landing_page/assets/cards/knight.jpg"
              backImage="/landing_page/assets/cards/card-back.png"
              altText="Knight Card"
              className="border-2 border-dotted border-cyberpunk-blue rounded-xl overflow-hidden"
            />
            <div className="absolute -top-8 -left-8">
              <img
                src="/landing_page/assets/icon/smile.svg"
                alt=""
                className="w-12 h-12"
              />
            </div>
          </div>
        </div>

        {/* Right Card Section */}

        <div className="absolute left-1/2 top-36 transform rotate-12 w-16 md:w-20 lg:w-24 hidden lg:block">
          <div
            className={`transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
              }`}
          >
            <Card
              frontImage="/landing_page/assets/cards/joker.png"
              backImage="/landing_page/assets/cards/card-back.png"
              altText="Joker Card"
              className="border-2 border-dotted border-cyberpunk-blue rounded-xl overflow-hidden"
            />
            <div className="absolute -top-8 -right-8">
              <img
                src="/landing_page/assets/icon/splash.svg"
                alt=""
                className="w-12 h-12"
              />
            </div>
          </div>
        </div>

        {/* Text Content Section */}
        <div
          className={`absolute right-8 transition-all duration-1000 
    ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'}
    bottom-24 w-full lg:w-2/5 text-right
    sm:px-4 sm:bottom-32 sm:right-4`}
        >
          <div className="space-y-2">
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-departure-mono text-winter-night leading-tight">
              <ScrambleText text="M(IT)^2" />
            </h1>
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-departure-mono text-winter-night leading-tight">
              <ScrambleText text="WINTER 2025" />
            </h1>
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-departure-mono text-mitit-red leading-tight">
              <ScrambleText text="TOURNAMENT" />
            </h1>
          </div>

          <div className="space-y-4">
            <p className="text-lg md:text-xl font-departure-mono text-mitit-red">
              <ScrambleText text="JANUARY 19, 2025" />
            </p>
            <p className="text-sm md:text-base lg:text-lg font-outfit text-winter-night max-w-xl ml-auto">
            The MIT Informatics Tournament is the premier programming competition for high school and college students hosted by students at MIT.
            </p>
            <Link
              to="/logistics"
              className="inline-block secondary-button transition-all duration-300"
            >
              LEARN MORE
            </Link>
          </div>
        </div>

        {/* Scroll Indicator */}
        <div
          className={`absolute bottom-8 left-1/2 transform -translate-x-1/2 text-center transition-all duration-1000 hidden md:block ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
            }`}
        >
          <div className="relative w-24 h-24">
            <img
              src="/landing_page/assets/icon/cloud-trinket.svg"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="mt-4 animate-bounce">
            <img
              src="/landing_page/assets/icon/scroll-trinket.svg"
              alt=""
              className="w-6 h-6 mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHero;